import type {ClientElementAction} from "pg-isomorphic/api/clientTrigger";
import type {InjectionKey, ComputedRef, Ref} from "vue";
import type {
  CancelHandler,
  ChangeAnswerHandler,
  ChangeEditStateHandler,
  ChangeInstanceShownStateHandler,
  ChangeInstanceStateHandler,
  ChangeTopicStateHandler,
  CheckEditStateMethod,
  AddInstanceMethod,
  RemoveInstanceMethod,
  EditRules,
  EditState,
  EditStateScope,
  generateElementActionsMethod,
  GetOptionsTreeData,
  InstanceStates,
  QuestionTreeSectionSpecificData,
  RefPopData,
  RemoveAnswerHandler,
  SaveHandler,
  SaveSingleAnswerHandler,
  showElementActionsMethod,
  ValidationState,
  QuestionInfoPaneData,
  GroupsAddingNewInstance,
} from "@/composables/questions/types";
import type {JSONQuestion} from "pg-isomorphic/utils";
import type {Answers} from "pg-isomorphic/api/answers";
import type {FilterRules} from "pg-isomorphic/profile/filter";
import type {Triggers} from "@/composables/connections/Triggers";

// Edit States
export const ChangeEditStateKey: InjectionKey<ChangeEditStateHandler> = Symbol("changeEditState");
export const InEditStateComputedKey: InjectionKey<CheckEditStateMethod> = Symbol("editStateMethod");
export const EditStateKey: InjectionKey<Ref<EditState>> = Symbol("EditState");
export const CanEditKey: InjectionKey<Ref<boolean>> = Symbol("canEdit");
export const IsEditingKey: InjectionKey<Ref<boolean>> = Symbol("isEditing");

// Topic Open States
export const ChangeTopicStateKey: InjectionKey<ChangeTopicStateHandler> = Symbol("changeTopicState");
export const InTopicOpenStateKey: InjectionKey<(key: string, openByDefault?: boolean) => ComputedRef<boolean>> =
  Symbol("topicStateMethod");

// Instance States
export const ChangeInstanceStateKey: InjectionKey<ChangeInstanceStateHandler> = Symbol("changeInstanceState");
export const ResetInstanceStatesKey: InjectionKey<() => void> = Symbol("resetInstanceStates");
export const InInstanceOpenStateKey: InjectionKey<(tableId: string, instanceNs: string) => boolean> =
  Symbol("inInstanceOpenState");
export const InstanceOpenStatesKey: InjectionKey<Ref<InstanceStates>> = Symbol("instanceStateKeys");
export const GroupsAddingNewInstanceKey: InjectionKey<Ref<GroupsAddingNewInstance>> = Symbol("groupsAddingNewInstance");

// Instance Shown States
export const ChangeInstanceShownStateKey: InjectionKey<ChangeInstanceShownStateHandler> =
  Symbol("changeInstanceShownState");
export const HasInstanceShownStateKey: InjectionKey<(instanceNs: string) => boolean> =
  Symbol("inInstanceOpenShownState");
export const ResetInstanceShownStateKey: InjectionKey<() => void> = Symbol("resetInstanceShownState");

// Manipulate Instances
export const MakeInstancePrimaryKey: InjectionKey<
  (instanceKey: string, questionData: JSONQuestion, withSave?: boolean) => Promise<void>
> = Symbol("makeInstancePrimary");
export const RemoveInstanceKey: InjectionKey<RemoveInstanceMethod> = Symbol("removeInstance");
export const AddInstanceKey: InjectionKey<AddInstanceMethod> = Symbol("addInstance");
export const AddKitInstanceKey: InjectionKey<AddInstanceMethod> = Symbol("addKitInstance"); // don't conflict with AddInstanceKey
export const RefreshReferenceMapKey: InjectionKey<() => Promise<void>> = Symbol("refreshParentChildMapping");

// Pass Data
export const AnswersKey: InjectionKey<Ref<Answers>> = Symbol("answers");
export const QuestionTreeDataKey: InjectionKey<Ref<JSONQuestion>> = Symbol("questions");
export const OriginalQuestionTreeDataKey: InjectionKey<JSONQuestion> = Symbol("originalQuestions");
export const AssociatedTasksKey: InjectionKey<Ref<any>> = Symbol("associatedTasks");
export const EditRulesKey: InjectionKey<Ref<EditRules>> = Symbol("editRules");
export const CounterPartyAnswersKey: InjectionKey<Ref<Answers>> = Symbol("counterPartyAnswers");
export const CounterPartyQuestionsKey: InjectionKey<Ref<JSONQuestion>> = Symbol("counterPartyQuestions");

// Answer Related
export const GetAnswerKey: InjectionKey<(questionData: Ref<JSONQuestion>, defaultAnswer?: any) => ComputedRef<any>> =
  Symbol("topicStateMethod");
export const ChangeAnswerKey: InjectionKey<ChangeAnswerHandler> = Symbol("changeAnswer");
export const ChangeKitAnswerKey: InjectionKey<ChangeAnswerHandler> = Symbol("changeKitAnswer"); // don't conflict with ChangeAnswerKey
export const CancelHandlerKey: InjectionKey<CancelHandler> = Symbol("cancelHandler");
export const SaveHandlerKey: InjectionKey<SaveHandler> = Symbol("saveHandler");
export const SaveKitHandlerKey: InjectionKey<SaveHandler> = Symbol("saveKitHandler");
export const SaveSingleAnswerHandlerKey: InjectionKey<SaveSingleAnswerHandler> = Symbol("saveSingleAnswerHandler");
export const RemoveAnswerKey: InjectionKey<RemoveAnswerHandler> = Symbol("removeAnswer");
export const unmarkInstanceAsDefaultKey: InjectionKey<(tableKey: string) => void> = Symbol("unMarkDefault");

// Element Actions
export const ShowElementActionsComputedKey: InjectionKey<showElementActionsMethod> = Symbol("showElementActions");
export const GenerateElementActionsComputedKey: InjectionKey<generateElementActionsMethod> =
  Symbol("generateElementActions");
export const UseElementActionsKey: InjectionKey<boolean> = Symbol("useElementActions");
export const CalendarAttachToKey: InjectionKey<string> = Symbol("calendarAttachTo");
export const ChatContainerElementKey: InjectionKey<string> = Symbol("ChatContainerElement");
export const QuestionInfoPaneDataKey: InjectionKey<Ref<QuestionInfoPaneData>> = Symbol("QuestionInfoPaneData");
export const UpdateQuestionInfoPaneDataKey: InjectionKey<(data: any) => void> = Symbol("UpdateQuestionInfoPaneData");
export const AdditionalActionsKey: InjectionKey<Ref<ClientElementAction[]>> = Symbol("additionalActions");

// Validation
export const UpdateValidationStateKey: InjectionKey<(validationKey: string, validationState: ValidationState) => void> =
  Symbol("updateValidationState");
export const RemoveValidationStateKey: InjectionKey<(validationKey: string) => void> = Symbol("removeValidationState");
export const UpdateShowValidationStatusKey: InjectionKey<(focused: boolean) => void> =
  Symbol("updateShowValidationStatus");

// Section Specific Data (tasks, etc)
export const SectionSpecificDataKey: InjectionKey<Ref<QuestionTreeSectionSpecificData>> = Symbol("sectionSpecificData");
export const CurrentEntityIdKey: InjectionKey<Ref<string>> = Symbol("currentEntityId");

// Filter
export const ChangeFilterKey: InjectionKey<
  (connectionUrlFilter: FilterRules, keysToUnset?: Array<keyof FilterRules>) => Promise<void>
> = Symbol("changeFilter");
export const FilterRulesKey: InjectionKey<Ref<FilterRules>> = Symbol("filterRules");
export const HideRiskScoreKey: InjectionKey<Ref<boolean>> = Symbol("hideRiskScore");

// OTHER
export const GetTopicReviewChangedAnswersKey: InjectionKey<(elementData: JSONQuestion) => Promise<number>> =
  Symbol("updateValidationState");
export const TopicsStartOpenedKey: InjectionKey<boolean> = Symbol("topicsStartOpened");
export const IsAdvancedSearch: InjectionKey<boolean> = Symbol("isAdvancedSearch");
export const EditStateScopeKey: InjectionKey<EditStateScope> = Symbol("editStateScope");
export const GetOptionsTreeDataKey: InjectionKey<GetOptionsTreeData> = Symbol("getOptionsTreeData");
export const UpdateRefPopupData: InjectionKey<(questionData: JSONQuestion, instance: string) => void> =
  Symbol("updateRefPopupData");
export const QuestionInputAutoFocusKey: InjectionKey<Ref<string>> = Symbol("questionInputAutoFocus");
export const QuestionInputAutoCompleteKey: InjectionKey<Ref<string>> = Symbol("questionInputAutoComplete");
export const DisplayTabsKey: InjectionKey<Ref<string[]>> = Symbol("displayTabs");
export const tabCountKey: InjectionKey<Ref<number>> = Symbol("tabCount");
export const DisplayTabOrTopicElementIdsKey: InjectionKey<Ref<String[]>> = Symbol("displayTabOrTopicElementIds");
export const DisplayOnlySpecificElementIdsKey: InjectionKey<Ref<String[]>> = Symbol("displayOnlySpecificElementIds");
export const HideTabLabels: InjectionKey<ComputedRef<boolean>> = Symbol("hideTabLabels");
export const RunInitializeQuestionDataKey: InjectionKey<() => void> = Symbol("RunInitializeQuestionData");
export const RunComputePropsTransformKey: InjectionKey<(key: string) => void> = Symbol("RunComputePropsTransform");
export const CurrentUserIsSuperAdminKey: InjectionKey<boolean> = Symbol("CurrentUserIsSuperAdmin");
export const ShowingQuestionKeysLoadingIndicatorKey: InjectionKey<Ref<string[]>> = Symbol(
  "ShowingQuestionLoadingIndicator",
);
export const ClientSideTriggerKey: InjectionKey<Triggers> = Symbol("ClientSideTrigger");

// REVIEW ONLY
export const InstanceEditStateKey: InjectionKey<Ref<string>> = Symbol("instanceEditStateKey");
export const ReviewLoadingKey: InjectionKey<Ref<boolean>> = Symbol("reviewLoading");
export const CompleteReviewKey: InjectionKey<
  (
    topicId: string,
    reviewGroupId: string,
    reviewInstanceKey: string,
    editKey: string,
    answers: any,
    newReview: boolean,
  ) => Promise<{error: string}>
> = Symbol("completeReview");
export const NewReviewKey: InjectionKey<
  (
    topicId: string,
    reviewGroupId: string,
    requestVerification: boolean,
    assessmentGroups: any[],
    additionalContactName: string,
    additionalContactEmail: string,
  ) => Promise<{error: string}>
> = Symbol("newReview");
export const DeleteReviewKey: InjectionKey<
  (topicId: string, reviewGroupId: string, reviewInstanceKey: string, deletionNote: string) => void
> = Symbol("deleteReview");
